import { requestApi } from "./index";

const statistics = {
  listStatistics: async (params = "") => requestApi("/admin2021/statistics" + params),
  listStatisticsTop: async (params = "") => requestApi("/admin2021/statistics/top" + params),
  listStatisticsByDate: async (params = "") => requestApi("/admin2021/statistics/by-date" + params),
  jobs: async (params = "") => requestApi("/admin2021/statistics/jobs" + params),
  coin: async (params = "") => requestApi("/admin2021/statistics/coin" + params),
  revenues: async (params = "") => requestApi("/admin2021/statistics/revenues" + params),
  byUsername: async (params = "") => requestApi("/admin2021/statistics/by-username" + params),
  byUsernameDetail: async (params = "") => requestApi("/admin2021/statistics/by-username-detail" + params),
};

export default statistics;
