<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Thống kê tài khoản {{ username }}</h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Thống kê tài khoản {{ username }}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Username</label>
                  <input type="text" class="form-control" disabled v-model="username" />
                </div>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Ngày bắt đầu</label>
                  <input type="text" class="form-control" disabled v-model="dateFrom" />
                </div>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Ngày kết thúc</label>
                  <input type="text" class="form-control" disabled v-model="dateTo" />
                </div>
              </div>
              <div class="row">
                <loading-vue v-if="loading" />
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title mb-4">Thống kê theo uid</h4>
                      <div class="table-responsive">
                        <table class="table align-middle table-nowrap mb-0">
                          <thead class="table-light">
                            <tr>
                              <th class="align-middle">Uid</th>
                              <th class="align-middle">Ngày thêm</th>
                              <th class="align-middle">Số jobs thành công</th>
                              <th class="align-middle">Số jobs thất bại</th>
                              <th class="align-middle">Tổng tiền làm được</th>
                              <th class="align-middle">Tổng tiền chờ duyệt</th>
                              <th class="align-middle">Tổng tiền đã duyệt</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(statistic, index) in data" :key="index">
                              <td>{{ statistic.uid }}</td>
                              <td>{{ statistic.date }}</td>
                              <td>{{ statistic.total_complete | formatNumber }}</td>
                              <td>{{ statistic.total_die | formatNumber }}</td>
                              <td>{{ statistic.total_coin | formatNumber }}</td>
                              <td>{{ statistic.total_pending_coin | formatNumber }}</td>
                              <td>{{ statistic.total_paid_coin | formatNumber }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import statisticsApi from "@/api/statistics";
import LoadingVue from "@/components/loading/Loading.vue";
import moment from "moment";
import { buildParamQuery } from "@/helpers";

export default {
  name: "StatisticsUsernameDetail",
  components: {
    LoadingVue,
  },
  data() {
    return {
      data: {},
      loading: false,
      username: null,
      dateFrom: null,
      dateTo: null,
    };
  },
  created() {
    this.username = this.$route.query.username;
    this.dateFrom = this.$route.query.date_from;
    this.dateTo = this.$route.query.date_to;
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const params = {
        username: this.username,
        date_from: this.dateFrom,
        date_to: this.dateTo,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await statisticsApi.byUsernameDetail(queries);
      this.loading = false;
      if (response) {
        if (response.success) {
          this.data = response.data || {};
        } else {
          this.$toastr.error(response?.message || "Tải dữ liệu không thành công !");
          this.data = {};
        }
      } else {
        this.$toastr.error("Tải dữ liệu không thành công !");
        this.data = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
