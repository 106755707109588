export const buildParamQuery = function (params) {
  let esc = encodeURIComponent;
  let keyArray = [];
  for (const key in params) {
    if (params.hasOwnProperty(key) && (params[key] || params[key] === 0)) {
      keyArray.push(key);
    }
  }
  let query = keyArray.map((k) => esc(k) + "=" + esc(params[k])).join("&");
  return query ? "?" + query : "";
};

export const catchError = function (data) {
  let errorText = "";
  try {
    if (
      data.message &&
      typeof data.mess === "string" &&
      data.error &&
      typeof data.error === "string"
    ) {
      errorText = data.message + "<br>" + data.error;
    } else if (
      data.error &&
      typeof data.error === "object" &&
      Object.keys(data.error).length !== 0
    ) {
      if (data.error.errors && typeof data.error.errors === "object") {
        errorText = handleErrorObject(data.error.errors);
      } else {
        errorText = handleErrorObject(data.error);
      }
    } else if (
      data.message &&
      typeof data.message === "object" &&
      Object.keys(data.message).length !== 0
    ) {
      if (data.message.error && typeof data.message.error === "object") {
        errorText = handleErrorObject(data.message.error);
      } else {
        errorText = handleErrorObject(data.message);
      }
    } else if (data.mess && typeof data.mess === "string") {
      errorText = data.mess;
    } else if (data.message && typeof data.message === "string") {
      errorText = data.message;
    } else if (data.error && typeof data.error === "string") {
      errorText = data.error;
    } else if (data.res && typeof data.res.error === "string") {
      errorText = data.res.error;
    } else if (data.data && typeof data.data.error === "string") {
      errorText = data.data.error;
    } else if (data.data && typeof data.data.message === "string") {
      errorText = data.data.message;
    }
  } catch (error) {
    errorText = "";
  }
  errorText =
    typeof errorText === "string" && errorText
      ? translateError(errorText)
      : "Vui lòng thử lại sau ít phút, hoặc liên hệ admin để hỗ trợ";
  return errorText;
};
const handleErrorObject = function (error) {
  let errorText = "";
  try {
    for (const key in error) {
      if (error.hasOwnProperty(key)) {
        if (Array.isArray(error[key])) {
          if (error[key][0].match(/taken/)) {
            errorText += key + " đã tồn tại trong hệ thống, vui lòng thử lại" + "\n";
          } else if (error[key][0].match(/valid/)) {
            errorText += key + " không hợp lệ, vui lòng thử lại" + "\n";
          } else if (error[key][0].match(/not match/)) {
            errorText += key + " không chính xác" + "\n";
          } else if (error[key][0].match(/required/)) {
            errorText += "Vui lòng nhập " + key + "\n";
          } else {
            errorText += error[key][0] + "\n";
          }
        } else if (error[key].match(/taken/)) {
          errorText += key + " đã tồn tại trong hệ thống, vui lòng thử lại" + "\n";
        } else if (error[key].match(/valid/)) {
          errorText += key + " không hợp lệ, vui lòng thử lại" + "\n";
        } else if (error[key].match(/not match/)) {
          errorText += key + " không chính xác" + "\n";
        } else if (error[key].match(/required/)) {
          errorText += "Vui lòng nhập " + key + "\n";
        } else {
          errorText += key + ": " + error[key] + "\n";
        }
      }
    }
  } catch (error) {
    errorText = "";
  }
  return errorText;
};
export const translateError = function (text) {
  text = text.replace("Trường number", "Số lượng");
  text = text.replace("Trường days", "Số ngày");
  text = text.replace(
    "We cant find an account with this credentials.",
    "Sai tên đăng nhập hoặc mật khẩu, vui lòng thử lại."
  );
  return text;
};

export const formatNumber = function (x) {
  return x
    ? Number(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : x;
};
